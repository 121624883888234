import Herosection from "../../components/Herosection/Herosection";
import SeehowWecanSection from "../../components/SeehowWecanSection/SeehowWecanSection";
import FeaturedLevelUp from "../../components/FeaturedLevelUp/FeaturedLevelUp";
import LevelUpLobby from "../../components/LevelUpLobby/LevelUpLobby";
import XpBoostStarts  from "../../components/XpBoostStarts/XpBoostStarts";
import LevelingUpTogether  from "../../components/LevelingUpTogether/LevelingUpTogether";
const HomePage = () => {

  return (
    <>
      <main>
        {/* hero banner component */}
            <Herosection />
            <LevelUpLobby />
        <FeaturedLevelUp />
        <SeehowWecanSection />
        <XpBoostStarts />
        <LevelingUpTogether />
      </main>
    </>
  );
};

export default HomePage;
