import React, { Component } from 'react'
import twitchcon1 from "../../Assets/images/twitchcon-1.png";
import twitchcon2 from "../../Assets/images/twitchcon-2.png";
import twitchcon3 from "../../Assets/images/twitchcon-3.png";
import twitchcon4 from "../../Assets/images/twitchcon-4.gif";
import twitchcon5 from "../../Assets/images/twitchcon-5.png";
import twitchcon6 from "../../Assets/images/twitchcon-6.png";
import twitchcon7 from "../../Assets/images/twitchcon-7.png";
import twitchcon8 from "../../Assets/images/twitchcon-8.png";
import twitchcon9 from "../../Assets/images/twitchcon-9.gif";
import twitchcon10 from "../../Assets/images/twitchcon-10.png";
import twitchconDiffLogo from "../../Assets/images/Logo Lockup.png";
import "./LevelingUpTogether.scss";


const LevelingUpTogether = () => {
    return (
      <section className="twitch-con section-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="logowrap" >
              <img  src={twitchconDiffLogo} alt="twitchconand Differinlogo"/>
            </div>
            <div className="title px-lg-2 px-md-5 px-sm-2 my-2">
              <h2 className="fullTitle">LEVELING UP TOGETHER</h2>
              <p className="">
                Differin is all about connecting gamers, building communities, and streaming with confidence. <br/>
                Conquer breakouts with Differin so you can focus on what really matters: making connections and epic wins.
              </p>
              <p className="d-block d-md-none d-lg-none d-xl-none d-xxl-none twichconlastp">Meet our featured Twitch Streamers and see how they level up their game. </p>
            </div>
          </div>
        </div>
        <div className="row g-2">
          <div className="col-12 col-lg-12">
            <img className="img-fluid" src={twitchcon1} alt="img1" />
          </div>
          <div className="col-12 col-lg-6">
            <img className="img-fluid" src={twitchcon2} alt="img2" />
          </div>
          <div className="col-12 col-lg-6">
            <div className="row g-2">
              <div className="col-12"><img className="img-fluid" src={twitchcon3} alt="img2" /></div>
              <div className="col-12"><img className="img-fluid" src={twitchcon4} alt="img2" /></div>
            </div>
          </div>
          <div className="col-12">
            <div className="row g-2">
              <div className="col-6 col-lg-3"><img className="img-fluid" src={twitchcon5} alt="img5" /></div>
              <div className="col-6 col-lg-3"><img className="img-fluid" src={twitchcon6} alt="img6" /></div>
              <div className="col-6 col-lg-3"><img className="img-fluid" src={twitchcon7} alt="img7" /></div>
              <div className="col-6 col-lg-3"><img className="img-fluid" src={twitchcon8} alt="img8" /></div>
            </div>
          </div>
          <div className="col-12 col-lg-6"><img className="img-fluid" src={twitchcon9} alt="img2" /></div>
          <div className="col-12 col-lg-6"><img className="img-fluid" src={twitchcon10} alt="img2" /></div>
        </div>
      </div>
    </section>

    )
  }


export default LevelingUpTogether