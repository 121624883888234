import { useEffect, useState } from 'react';

const useGameSchedule = () => {
  const [games, setGames] = useState<{ locked: {}, unlocked: {}}>({ locked: {}, unlocked: {}});

  const fetchScheduledGames = () => {
    fetch(`/games`, { method: 'GET'  })
      .then((response) => response.json())
      .then(({ games }) => setGames(games))
      .catch((e) => console.log(e));
  };

  useEffect(fetchScheduledGames, []);

  return {
    games,
  };
};

export default useGameSchedule;
