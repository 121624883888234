import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { URLConstant } from '../../routes';
import { processReceiptOCR } from './verificationHelpers';
import useLocalStorageState from '../../utils/useLocalStorageState';
import { EVENT_NAMES, trackEvent } from '../../utils/analytics';

type state = 'upload' | 'verifying' | 'invalidReceipt';

/**
 * Hook to handle the receipt scanning workflow
 */
const useReceiptVerification = () => {
  const [currentState, setCurrentState] = useState<state>('upload');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const { saveReceiptValidated } = useLocalStorageState();

  useEffect(() => {
    if (selectedFile) {
      const performVerification = async () => {
        trackEvent(EVENT_NAMES.receiptSubmit);

        const matches = await processReceiptOCR(selectedFile);

        trackEvent(EVENT_NAMES.receiptValidation, {
          isDifferinProduct: matches ? 'YES' : 'NO'
        });

        setSelectedFile(null);

        if (matches) {
          saveReceiptValidated();
          navigate(URLConstant.urls.selectGame);
        } else {
          setCurrentState('invalidReceipt');
        }
      };

      performVerification();
    }
  }, [selectedFile, navigate, saveReceiptValidated]);

  const verifyFile = async (file: File) => {
    setCurrentState('verifying');
    setSelectedFile(file);
  }

  const retry = () => {
    setCurrentState('upload');
  };

  return {
    currentState,
    verifyFile,
    retry,
  };
};

export default useReceiptVerification;