export class URLConstant {
  public static readonly urls = {
    homePage: "/",
    about: "/about",
    products: "/products",
    contact: "/contact",
    productsDetails: "/products/:productId",
    privacy: "/privacyPolicy",
    cookie: "/cookiePolicy",
    receiptUpload: "/receipt-upload",
    selectGame: "/select-game",
    TermsCondition: "/terms-condition",
  };
}
