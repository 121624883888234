export const EVENT_NAMES = {
  receiptSubmit: 'receipt_submit',
  receiptValidation: 'receipt_product_validation'
}

export const trackEvent = (eventName, params) => {
  window.dataLayer.push({
    event: eventName,
    ...params,
  });
}
