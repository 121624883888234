import React, { Component } from "react";
import { Link } from "react-router-dom";
import companylogoWhite from "../../Assets/images/differin-logo.png";
import LevelupDifferin01 from "../../Assets/images/levelup-differin-01.png";
import PlayLearnEarn from "../../Assets/images/levelup-play-learn-earn_up.gif";
import PlayMoreEarnMore from "../../Assets/images/levelup-playmore-earnmore.gif";
import BuyScanPlay from "../../Assets/images/website-video-loop_3_up.gif";
import "./LevelUpLobby.scss";

const LevelUpLobby = () => {
  return (
    <section className="videosection cesSection" id="section-2">
      <div className="container">
        <div className="row flex-column flex-lg-row-reverse justify-content-end align-items-start h-100">
          <div className="col-12 col-lg-5 text-left text-lg-start">
            <img
              className="ceslogo img-fluid"
              src={companylogoWhite}
              alt="cesLogoimage"
            ></img>
            <div className="title px-lg-0 px-sm-3 ">
              <h2>LEVEL UP LOBBY</h2>
              <p>
                Introducing the Differin Level Up Lobby on Roblox, a community
                packed with acne-care mini-games, exclusive power-ups in the
                games that you know and love, plus a chance to win exclusive UGC
                - all powered by Differin’s acne-fighting arsenal. Level up your
                skin and your game!
              </p>
              <div className="col-12">
            <Link to="/receipt-upload"
                      className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4" >
                     <span>Learn More</span>
                    </Link>
            </div>
            </div>
          </div>
          <div className="col-12 col-lg-7 mobilview-btn">
            <div>
              <img
                className="img-fluid"
                src={LevelupDifferin01}
                alt="LEVEL UP LOBBY Differin"
              ></img>
            </div>
            <div className="d-flex justify-content-lg-start">
                <Link to="/terms-condition" target="_blank" className="privacyLink tcbtn" ><span>Terms &amp; Conditions</span>
                </Link>
              </div>
          </div>
        </div>
        <div className="row align-items-end justify-content-between d-none">
            <div className="col-12 col-lg-7">
              <div className="d-flex justify-content-lg-start">
                <Link to="/terms-condition" target="_blank" className="privacyLink tcbtn" ><span>Terms &amp; Conditions</span>
                </Link>
              </div>
            </div>
            <div className="col-12 col-lg-5 px-sm-3">
            <Link to="/receipt-upload"
                      className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4" >
                     <span>Learn More</span>
                    </Link>
            </div>
        </div>
        <div className="row align-items-start justify-content-between">
            <div className="col-12 col-lg-4">
                <div className="play-levelup">
                    <div className="play-levelup-img"><img className="img-fluid" src={PlayLearnEarn} alt="Play Learn Earn" /></div>
                    <div className="play-levelup-text">
                        <p>Play • Learn • Earn</p>
                        <text>Complete the acne-care mini-games with Differin’s acne-fighting arsenal to level up your skin and your game with in-game rewards.</text>
                    </div>
                </div>

            </div>
            <div className="col-12 col-lg-4">
                <div className="play-levelup">
                    <div className="play-levelup-img"><img className="img-fluid" src={PlayMoreEarnMore} alt="Play More Earn More" /></div>
                    <div className="play-levelup-text">
                        <p>Play More • Earn More</p>
                        <text>The more you play, the more you earn. Visit regularly to win-game reward and a chance for an exclusive UGC on the 5th visit.</text>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="play-levelup">
                    <div className="play-levelup-img"><img className="img-fluid" src={BuyScanPlay} alt="Buy Scan Play" /></div>
                    <div className="play-levelup-text">
                        <p>Buy • Scan • Play</p>
                        <text>Scan a photo of a receipt for any Differin product from anywhere Differin is sold to receive an in-game reward.</text>
                    </div>
                </div>
                <div className="levelup-btn">
                <Link to="/receipt-upload"
                      className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4" >
                     <span>Scan Now!</span>
                    </Link>
                {/* <a
                className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                target="_blank"
                href="https://www.ces.tech/schedule/level-up-building-confidence-and-community-for-gamers/"
              >
                <span>Learn More</span>
              </a> */}
                </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default LevelUpLobby;
