import React, { Component } from 'react'

import DIFFERIN_V111 from "../../Assets/images/differin-trailer.jpg";
const s3video = "https://galderma-s3-bucket.s3.us-east-1.amazonaws.com/Prod/differin-trailer01.mp4"


const SeehowWecanSection = () => {
    return(
        <>
            <section className="videosection seehowUpdatedImage section-2">
          <div className="container">
            <div className="row">
                <h2 className="mx-auto text-uppercase text-center text-uppercase text-white fw-bolder max-w-700px mb-xl-5 mb-lg-4 mb-md-4 mb-sm-4 mb-4">
                  See HOW You CAN POWER UP YOUR acne game.
                </h2>
            </div>
              <video
                id="my-video-1"
                className="video-js vjs-default-skin mx-auto"
                controls
                preload="none"
                poster={DIFFERIN_V111} controlsList="nodownload"
                data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'
              >
                {s3video && (
                  <source src={s3video} type="video/mp4" />
                )}
              </video>



          </div>
        </section>
        </>
    );
}

export default SeehowWecanSection;

