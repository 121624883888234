import React, { Component } from 'react'
import companylogo from "../../Assets/images/logo.png";
import AcneFomingCleanser from "../../Assets/images/acneCleanser.png";
import AcnePacth from "../../Assets/images/AcnePatch.png";
import AcneSpot from "../../Assets/images/Acnespot.png";
import "./FeaturedLevelUp.scss";


const FeaturedLevelUp = () => {
    return (
      <section className="levelup-bg section-3">

      <div className="container py-xl-0 py-lg-0 py-md-3 py-sm-3 py-3 ps-lg-0 ps-xl-5">
        <div className="align-items-center lineup row m-0" >
          <div className="col-12">
            <div className="title">
              <h2 className="fullTitle">FEATURED LEVEL UP LINEUP</h2>
              <p>
              It’s time to level up your skin! The Level Up featured lineup equips you
              with Differin’s acne-fighting arsenal. Take control,
              clear the map of breakouts, and achieve the high score in glowing skin. 
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-4 pt-5">
            <div className="prodimageWrap">
                <img className="img-fluid" src={AcneFomingCleanser} />
            </div>
            <div className="Prodinfo">
              <div className="content">
                <h3>Acne Foaming Cleanser</h3>
                <p>Maximum Strength Acne Foaming Cleanser with 10% Benzoyl Peroxide has the strongest concentration available without a prescription.</p>

              </div>
              <a
                  className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                  target="_blank"
                  href="https://www.differin.com/shop/Maximum-Strength-Acne-Foaming-Cleanser/302994137008.html"
                >
                  <span>Learn More</span>
                </a>
            </div>
          </div>
          <div className="col-12 col-lg-4 pt-5">
            <div className="prodimageWrap">
                <img className="img-fluid" src={AcnePacth} />
            </div>
            <div className="Prodinfo">
              <div className="content">
                <h3>Acne-Prone Skin Patches</h3>
                <p>Differin Power Patches are formulated with salicylic acid to exfoliate to help shrink the appearance of emerging pimples day and night, keeping your game going.  </p>
              </div>
              <a
                  className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                  target="_blank"
                  href="https://www.differin.com/shop/salicylic-acid-acne-prone-patches/4260328611456.html"
                >
                  <span>Learn More</span>
                </a>
            </div>
          </div>
          <div className="col-12 col-lg-4 pt-5">
            <div className="prodimageWrap">
                <img className="img-fluid" src={AcneSpot} />
            </div>
            <div className="Prodinfo">
              <div className="content">
                <h3>Acne Spot Treatment</h3>
                <p>Contains 10% Benzoyl Peroxide, the maximum strength available over-the counter, to clear blemishes, reduce acne size and redness.</p>
              </div>
              <a
                  className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                  target="_blank"
                  href="https://www.differin.com/shop/10%25Benzoyl-Peroxide-Spot-Treatment/302994611003.html"
                >

                  <span>Learn More</span>
                </a>
            </div>
          </div>

        </div>
      </div>
    </section>

    )
  }


export default FeaturedLevelUp