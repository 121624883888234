import GameImg1 from '../../../Assets/images/paradise rp.png';
import GameImg2 from '../../../Assets/images/game-2.png';
import GameImg3 from '../../../Assets/images/game-3.png';
import GameImg4 from '../../../Assets/images/game-4.png';
import GameImg5 from '../../../Assets/images/game-5.png';
import Diamond from '../../../Assets/images/diamond.png';
import Coins from '../../../Assets/images/coins.png';
import Star from '../../../Assets/images/star.png';
import Energy from '../../../Assets/images/Energy.png';
import lockIcon from '../../../Assets/images/lockicon.png';

import '../SelectGamePage.scss';
import formatDate from '../../../utils/formatDate';

export interface IGame {
    gameId: number;
    placeId: number;
    name: string;
    nameSuffix?: string
    multiplier: string;
    icon: string;
    img: string;
    date: number;
    dateType: 'start' | 'end';
}

interface Props {
    game: IGame;
    locked?: boolean;
    onClick: any;
}

const gameImages = {
    GameImg1,
    GameImg2,
    GameImg3,
    GameImg4,
    GameImg5,
}

const icons = {
    Diamond,
    Coins,
    Star,
    Energy,
}

const SelectGameItem = ({ game, onClick, locked = false }: Props) => {
    const { name, nameSuffix, multiplier, icon, img, placeId, date, dateType } = game;

    return (
        <div className='col-12 col-md-6'>
            <div className={`gameDetail${!locked ? ' unlocked' : ''}`} onClick={onClick ? () => onClick(placeId) : undefined}>
                <h3 className='gameType'>
                    <span>
                        {name}
                        {' '}
                        {nameSuffix && <span>{nameSuffix}</span>}
                    </span>
                    {date && <p>{dateType === 'start' ? 'Starts' : 'Ends'} {formatDate(date)}</p>}
                </h3>
                <div className='gameCardWrap'>
                    <div className={`gameCard${locked ? ' lock' : ''}`}>
                        <img className='img-fluid gameThumbnail' src={gameImages[img as 'GameImg1' | 'GameImg2' | 'GameImg3' | 'GameImg4' | 'GameImg5']} alt="thumbnail" />

                        {/* <div className='gameTypeHighlighter' />
                        <div className='gametypeDetails'>
                            <div className='gameTypeIconWrap'>
                                <img className='img-fluid' src={icons[icon as 'Diamond' | 'Coins' | 'Star' | 'Energy']} alt="GameTypeIcon" />
                            </div>
                            <p>{multiplier}</p>
                        </div> */}

                        {locked && (
                            <div className='lockIconWrap'>
                                <img src={lockIcon} alt="lockIcon" />
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SelectGameItem