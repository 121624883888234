import { useEffect } from 'react'

import LiveGame from '../../Assets/images/live games.png';
import comingsoon from '../../Assets/images/comingsoon.png';

import useLocalStorageState from '../../utils/useLocalStorageState';

import './SelectGamePage.scss';
import SelectGameItem, { IGame } from './elements/SelectGameItem';
import useDeepLink from '../../utils/useDeepLink';
import useGameSchedule from '../../utils/useGameSchedule';

export const SelectGamePage = () => {
    const { ensurePreviouslyValidatedReceipt } = useLocalStorageState();
    const { games } = useGameSchedule();
    const { fetchDeepLink } = useDeepLink();

    /**
     * Will redirect to upload receipt page if no receipt found in local storage
     */
    useEffect(ensurePreviouslyValidatedReceipt);

    return (
        <div className='selectGamePageWrap'>
            <div className='container py-4 py-md-5'>
                <div className='row m-0'>
                    <div className='col-12'>
                        <div className='title pb-4'>
                            <h1>Pick a Game, Play, and Power Up!</h1>
                        </div>
                    </div>
                </div>

                {Object.values(games.unlocked).length > 0 && (
                    <div className='row m-0'>
                        <div className='col-12'>
                            <h2 className='gamesStatusTitle'> <img src={LiveGame} alt="" /><span>Live Games</span></h2>
                        </div>
                        {Object.values(games.unlocked).map((game) => <SelectGameItem key={(game as IGame).placeId} game={(game as IGame)} onClick={fetchDeepLink} />)}
                    </div>
                )}

                <div className='row m-0'>
                    <div className='col-12'>
                        <h2 className='gamesStatusTitle'> <img src={comingsoon} alt="" /><span>Coming Soon</span></h2>
                    </div>
                    {Object.values(games.locked).map((game) => <SelectGameItem key={(game as IGame).placeId} game={(game as IGame)} onClick={null} locked={true} />)}
                </div>
            </div>
        </div>
    )
}
