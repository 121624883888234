interface Props {
  onRetryClicked(): void;
}

export const ReceiptNotVerified = ({ onRetryClicked }: Props) => {
  return (
    <div className='receiptWrap notverified'>
        <div className='container section-1 '>
            <div className='row m-0'>
                <div className='col-12 col-md-8 col-lg-7 '>
                    <div className='title'>
                        <h1>OOPS... Receipt Not Verified!</h1>
                    </div>
                    <p>
                        We couldn’t find a Differin purchase on your receipt photo. Don’t worry — let’s try again! Make sure your receipt is clear and includes a Differin product.
                    </p>
                </div>
                <div className='col-12'>
                    <div className='helpfultips'>
                        <p>Helpful Tips:</p>
                        <ul>
                            <li>Ensure your receipt is fully visible in the photo. </li>
                            <li>Use a clear, well-lit photo with no shadows or glare.</li>
                            <li>Check that the text on your receipt photo is easy to read and not blurry.</li>
                            <li>If possible, take the photo on a flat surface to avoid distortion.</li>
                        </ul>
                    </div>
                </div>
                <div className='col-12 btnActionWrap'>
                  <button
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    onClick={onRetryClicked}
                  >
                    Try Again
                  </button>
                </div>

            </div>

        </div>
    </div>
  )
}
