import React, { Component } from 'react'
import DESKTOP_BANNER from "../../Assets/images/DESKTOP_BANNER.png";
// import DESKTOP_BANNER from "../../../Assets/images/DESKTOP_BANNER.png";
import MOBILE_BANNER from "../../Assets/images/MOBILE_BANNER.png";
import dropdown from "../../Assets/images/downarrow.gif";

import "./Herosection.scss";
import { Link } from 'react-router-dom';
const desktopVideo = "https://galderma-s3-bucket.s3.us-east-1.amazonaws.com/Prod/desktop-firstsection-v2.mp4";
const MobileVideo  = "https://galderma-s3-bucket.s3.us-east-1.amazonaws.com/Prod/mobile-firstsection-v2.mp4";

const Herosection = () => {

    return (
        <>
            <section className="Videobanner-desktop section-1 d-none d-lg-block h-100">
          <div className="position-relative bg-7e02a9">
            <video
              autoPlay
              playsInline
              muted
              loop
              poster={DESKTOP_BANNER}
              className="w-100 h-100 mt-minus"
            >
              {desktopVideo && (
                <source src={desktopVideo} />
              )}

            </video>
            <a
              href="#section-2"
              className="position-absolute start-50 translate-middle-x bottom-4 dropdown-section-1"
            >
              <img className="dropdown-icon" src={dropdown} alt="dropdown-icon" />
            </a>
            <div className="container">
              <div className="row">
                <div className="col-md-5 col-lg-5 col-sm-12">
                <div className="pe-3 position-absolute ps-lg-0 ps-xl-0 title top-50 translate-middle-y w-50 pb-lg-4 pb-xl-5">
                <h1>DEFEAT THE BREAKOUTS. LEVEL UP YOUR SKIN.</h1>
                    <p className="max-w-450px">
                    Unlock clearer skin with Differin’s acne-fighting lineup.
                    </p>
                    <Link to="https://www.differin.com/Products" className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank">
                    <span>Learn More</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Videobanner-mobile section-1 d-block d-lg-none">
          <div className="position-relative  h-100">
            <video
              autoPlay
              muted
              playsInline
              loop
              poster={MOBILE_BANNER}
              className="w-100 h-100 mw-100"
            >
              {MobileVideo && <source src={MobileVideo} />}

            </video>
            <a
              href="#section-2"
              className="position-absolute start-50 translate-middle-x bottom-4 dropdown-section-1"
            >
              <img className="dropdown-icon" src={dropdown} alt="dropdown-icon" />
            </a>
            <div className="container ">
              <div className="row">
                <div className="col-12">
                <div className="position-absolute px-3 py-5 title top-0 py-md-5 my-md-5">
                    <h1 className="pt-4">DEFEAT THE BREAKOUTS. LEVEL UP YOUR SKIN.</h1>
                    <p>
                    Unlock clearer skin with Differin’s acne-fighting lineup.
                    </p>
                    <a
                    className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                    target="_blank"
                    href="https://www.differin.com/Products"
                  >
                    <span>Learn More</span>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>

    )
}

export default Herosection;
