
import "./TermsAndCondition.scss";

// Component


import TermsAndConditionContent from "../../components/TermsAndConditionContent/TermsAndConditionContent";

const TermsAndCondition = () => {

  return (
    <>
      <main>
            <TermsAndConditionContent />
      </main>
    </>
  );
};

export default TermsAndCondition;
