

import React from 'react';

import "./ScanningReceipt.scss";

export const ScanningReceipt = () => {
  return (
    <div className='receiptWrap'>
        <div className='container section-1 '>
            <div className='row m-0'>
                <div className='col-12 col-md-7 '>                        
                    <div className='title'>
                        <h1>Scanning Your Receipt...</h1>
                    </div>                        
                        <p>
                        Hang tight! We're verifying your receipt for a Differin purchase. This should only take a moment.
                        </p>
                                   
                </div>                
            </div>
            <div className='loaderwrap text-center'>
                <div className="loader"></div>
            </div>
           
        </div>
    </div>
  )
}
