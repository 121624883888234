import { useEffect } from 'react';

import { ReceiptUpload } from '../../components/ReceiptUpload/ReceiptUpload'
import { ScanningReceipt } from '../../components/ScanningReceipt/ScanningReceipt'
import { ReceiptNotVerified } from '../../components/ReceiptNotVerified/ReceiptNotVerified'

import './ReceiptUplaodPage.scss';
import useReceiptVerification from './useReceiptVerification'

export const ReceiptUplaodPage = () => {
  const { currentState, verifyFile, retry } = useReceiptVerification();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on route change
  }, [currentState]);

  return (
    <div className='receiptWrap'>
      {currentState === 'upload' && (
        <ReceiptUpload onSubmit={verifyFile} />
      )}

      {currentState === 'verifying' && (
        <ScanningReceipt />
      )}

      {currentState === 'invalidReceipt' && (
        <ReceiptNotVerified onRetryClicked={retry} />
      )}
    </div>
  );
}
