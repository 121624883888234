import { useNavigate } from 'react-router-dom';
import { URLConstant } from '../routes';

const useDeepLink = () => {
    const navigate = useNavigate();

    const fetchDeepLink = async (placeId: number) => {
        const { url } = await fetch(`/games/${placeId}`, { method: 'POST' })
            .then((response) => response.json())
            .catch(() => ({ url: null }));

        /**
         * The game has been found in the schedule and a url was generated
         */
        if (url) {
            window.open(url, '_blank');
        
        /**
         * No url was generated, so either the game is not scheduled or something has gone wrong
         */
        } else {
            navigate(URLConstant.urls.homePage);
        }
    };

    return {
        fetchDeepLink,
    };
};

export default useDeepLink;
