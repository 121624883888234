import Tesseract from "tesseract.js";

const ALLOWED_PRODUCTS = [
  'dif',
  'diif',
];

// Load and resize the receipt image
const loadAndResizeImage = (file: File, maxSize: number = 800): Promise<HTMLCanvasElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d")!;
      let width = img.width;
      let height = img.height;

      // Maintain aspect ratio while resizing
      if (width > maxSize || height > maxSize) {
        if (width > height) {
          height = Math.round((maxSize / width) * height);
          width = maxSize;
        } else {
          width = Math.round((maxSize / height) * width);
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas);
    };
    img.onerror = () => reject("Image load failed.");
    img.src = URL.createObjectURL(file);
  });
};

// Preprocess the receipt image using OpenCV.js
const preprocessImage = async (file: File, maxSize: number = 1200): Promise<HTMLCanvasElement> => {
  const canvas = await loadAndResizeImage(file, maxSize);
  return canvas;
};

// Process Receipt OCR
const processReceiptOCR = async (file: File) => {
  const canvas = await preprocessImage(file);

  const worker = await Tesseract.createWorker('eng');
  worker.setParameters({
    tessedit_char_whitelist: 'DIF',
    tessedit_pageseg_mode: Tesseract.PSM.SPARSE_TEXT,
  });
  const { data: { text } } = await worker.recognize(canvas);
  const modifiedText = text.replace(/\n/g, '');

  return ALLOWED_PRODUCTS.some((allowed) => modifiedText.toLowerCase().includes(allowed));
};

export {
  processReceiptOCR,
}