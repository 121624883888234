import React, { Component } from 'react'
import companylogo from "../../Assets/images/logo.png";
import Product4 from "../../Assets/images/xpboostProdImg.png";
import Product41 from "../../Assets/images/differin_trio 1.png";
import "./XpBoostStarts.scss";
import uparrow from '../../Assets/images/uparrow.gif';

const XpBoostStarts = () => {
    return (
      <section className="levelup-bg xpBoost section-5 position-relative pb-xl-0 pb-lg-0 pb-md-5 pb-sm-5 pb-5">
      {/* <img src={levelup} alt="levelupbg" /> */}

      <div className="container position-relative px-0">
        <div className="position-absolute end-0 img-container-products d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none">
          <div className="xpboostDeskImg">
              <div className="position-absolute downarrow">
                <img src={uparrow} alt="uparrow" className="uparrow" />
              </div>
              <img
                className="img-fluid"
                src={Product4}
                alt="Product"
              />
            </div>
        </div>
      <div className="row d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block pt-5 mx-0">
          <div className="col-12 d-flex justify-content-end px-0">
            <div className="position-relative max-w-730px">
              <div className="position-absolute downarrow">
                <img src={uparrow} alt="uparrow" className="d-block uparrow" />

              </div>
              <div className="imgwrapper">
                <img
                  className="img-fluid"
                  src={Product41}
                  alt="Product"
                />
              </div>

            </div>
          </div>
        </div>
        <div className="row levelup-text-sec">
          <div className="col-12 px-0">
            <div className="title px-xl-5 px-lg-0 px-md-5 px-sm-4 px-3 mx-xl-0 mx-lg-0 mx-md-0 mx-sm-1 mx-1 my-5">
              <h2>XP BOOST ACTIVATED. FIND DIFFERIN ONLINE. </h2>
              <p className="max-w-386px">
                Tired of battling stubborn breakouts? It’s time to elevate your skincare routine. Differin is the secret weapon that has clinically proven ingredients for battling those nasty pimples. It’s like a cheat code for acne. Make every day a win with Differin.
              </p>
              <a
                className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4"
                target="_blank"
                href="https://www.amazon.com/stores/Differin/page/7EAC0F7D-69DD-43B9-ABD2-1AC0783F7FDC?ref_=ast_bln&store_ref=bl_ast_dp_brandLogo_sto"
              >
                <span>Buy Differin Now</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    )
  }


export default XpBoostStarts