import React, { useState } from "react";
import './TermsAndCondtionFooter.scss';
import { Link, useLocation } from 'react-router-dom';
export const TermsAndCondtionFooter = () => {
  const location = useLocation();
  if (location.pathname === "/terms-condition") {
    return null;
  }
  return (
    <>
      <div className='TermsandConditionFooter'>
          <Link to="/terms-condition" target="_blank"
                        className="tandcLink" >
           Terms &amp; Conditions
          </Link>
      </div>
    </>
  )
}