import { useNavigate } from 'react-router-dom';
import { URLConstant } from '../routes';

const RECEIPT_KEY = 'differin_receipt';
const RECEIPT_VALUE = 'validated';

const useLocalStorageState = () => {
  const navigate = useNavigate();

  const hasPreviousData = () => {
    return localStorage.getItem(RECEIPT_KEY) === RECEIPT_VALUE;
  }

  const ensurePreviouslyValidatedReceipt = () => {
    if (!hasPreviousData()) {
      navigate(URLConstant.urls.receiptUpload);
    }
  };

  const redirectOnExistingReceipt = () => {
    if (hasPreviousData()) {
      navigate(URLConstant.urls.selectGame);
    }
  }

  const saveReceiptValidated = () => {
    localStorage.setItem(RECEIPT_KEY, RECEIPT_VALUE);
  }

  return {
    ensurePreviouslyValidatedReceipt,
    redirectOnExistingReceipt,
    saveReceiptValidated,
  }
};

export default useLocalStorageState;
