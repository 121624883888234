import { useEffect } from 'react';
import UploadReceipt from "../../Assets/images/uploadreceiptpage.png";
import useLocalStorageState from '../../utils/useLocalStorageState';

import "./ReceiptUpload.scss";
import { Link } from 'react-router-dom';

interface Props {
  onSubmit(file: File): void;
}

export const ReceiptUpload = ({ onSubmit }: Props) => {
  const { redirectOnExistingReceipt } = useLocalStorageState();

  useEffect(redirectOnExistingReceipt);

  return (
        <div className='receiptWrap'>
            < div className='container section-1 '>
                <div className='row m-0'>
                    <div className='col-12 col-md-8 '>
                        <div className='title uploadRecipt'>
                            <h1>Upload your receipt</h1>
                        </div>

                         <p>
                            Use our receipt scanner to verify your purchase. Our smart technology will detect your Differin purchase and move you to the next step.
                         </p>
                        <p><strong>See helpful tips </strong></p>

                        <a className="btn btn-outline-light learn-more-btn mt-0 mt-lg-4 uploadbtn">
                          <input
                            type="file"
                            className="fileInput"
                            accept="image/*"
                            capture
                            onChange={({ target: { files } }) => {
                              if (files && files.length > 0) {
                                onSubmit(files[0]);
                              }
                            }}
                          />
                          <span>Upload Photo</span>
                        </a>
                        <Link to="/terms-condition" target="_blank" className="privacyLink" >
                        Terms &amp; Conditions
                        </Link>
                    </div>
                    <div className='col-12 col-md-4'>
                        <div className='ulpareceiptImg text-center'>
                            <img src={UploadReceipt} alt="uploadreceipt"></img>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='helpfultips'>
                            <p>Helpful Tips:</p>
                            <ul>
                                <li>Ensure your receipt is fully visible in the photo. </li>
                                <li>Use a clear, well-lit photo with no shadows or glare.</li>
                                <li>Check that the text on your receipt photo is easy to read and not blurry.</li>
                                <li>If possible, take the photo on a flat surface to avoid distortion.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


  )
}
